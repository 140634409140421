import React from "react";
import Layout from "~components/layouts/index";
import Col from "~components/ui/col";
import Row from "~components/ui/row";

const IndexPage = () => {
  return (
    <Layout>
      <Row align='center' justify='center'>
        <Col xs={2} sm={8} md={8} lg={12} xlg={12}>
          <h1>home</h1>
        </Col>
      </Row>
    </Layout>
  );
};

export default IndexPage;
